<template>
	<div class="login">
		<div class="warp" v-if="windowWidth>=1200">
			<div class="lo_wa_div">
				<div class="left">
					<el-image class="le_logo" :src="require('@/assets/img/logo.png')" :fit="'fill'"></el-image>
					<div>
						<h3 class="le_text1">{{$t('login.content.title')}}</h3>
						<h3 class="le_text2">√ {{$t('login.content.text1')}}</h3>
						<h3 class="le_text2">√ {{$t('login.content.text2')}}</h3>
						<h3 class="le_text2">√ {{$t('login.content.text3')}}</h3>
						<h3 class="le_text2">√ {{$t('login.content.text4')}}</h3>
						<h3 class="le_text2">√ {{$t('login.content.text5')}}</h3>
						<h3 class="le_text2">√ {{$t('login.content.text6')}}</h3>
						<el-button class="le_button" type="info" icon="el-icon-search">{{$t('login.content.button')}}</el-button>
					</div>
				</div>
				<div class="right">
					<div class="language" @click="i18nClick">
						<div>{{language}}</div>
						<a-icon class="lu_icon" type="caret-down" />
					</div>
					<div class="ri_div">
						<div class="ri_div_title">
							<span class="ri_div_ti_text">{{$t('login.right.title')}}</span>
						</div>
						<el-radio-group v-model="radio" >
						    <el-radio :label="0">{{$t('login.right.text3')}}</el-radio>
						    <el-radio :label="1">{{$t('login.right.text4')}}</el-radio>
						</el-radio-group>
						<div class="ri_div_content" v-if="radio==0">
							<el-form ref="ruleForm" :model="ruleForm" :rules="loginRules" label-width="">
								<el-form-item prop="mobile">
									<div class="inputDiv">
										<a-icon type="user" class="id_icon" />
										<el-input :placeholder="$t('login.right.mobile')" v-model="ruleForm.mobile" style="border: 0px;" type="text" />
									</div>
								</el-form-item>
								<el-form-item prop="pword">
									<div class="inputDiv">
										<a-icon type="lock" class="id_icon" />
										<el-input :placeholder="$t('login.right.pword')" v-model="ruleForm.pword" style="border: 0px;" type="password" />
									</div>
									<!-- <div style="color: #f56c6c;font-size: 12px;line-height: 1;padding-top: 4px;position: absolute;top: 100%;left: 0;">1111111111</div> -->
								</el-form-item>
								<!-- <el-form-item>
									<no-captcha style="width: 100%;" @slideCallback="finishSlide" :reload="reload" />
								</el-form-item> -->
							</el-form>
							<div style="display: flex;justify-content: left;">
								<el-checkbox v-model="ruleForm.rememberMe" style="margin:0px 0px 25px 0px;">{{$t('login.right.remember')}}</el-checkbox>
							</div>
							
							<el-button :loading="loading" size="medium" type="danger" style="width:100%;"
								@click.native.prevent="handleLogin">
								<span v-if="!loading">{{$t('login.right.button1')}}</span>
								<span v-else>{{$t('login.right.button2')}}...</span>
							</el-button>
							
							<div class="register">
								{{$t('login.right.text1')}}
								<!-- <router-link style="color: #d81e06;" class="link-type" :to="'/register'">立即注册</router-link> -->
								<el-link type="danger" href="/register">{{$t('login.right.text2')}}</el-link>
							</div>
							
							<!-- <div class="register2">
								<el-link type="danger" href="/userLogin">{{$t('login.right.text5')}}</el-link>
							</div> -->
						</div>
						
						<div class="ri_div_content" v-if="radio==1">
							<el-form ref="ruleForm" :model="ruleForm2" :rules="loginRules" label-width="">
								<el-form-item prop="mobile">
									<div class="inputDiv">
										<a-icon type="user" class="id_icon" />
										<el-input :placeholder="$t('login.right.mobile')" v-model="ruleForm2.mobile" style="border: 0px;" type="text" />
									</div>
								</el-form-item>
								<el-form-item prop="smscode">
									<div style="display: flex;">
										<el-input style="margin-right: 20px;" v-model="ruleForm2.smscode" type="text" auto-complete="off"
											:placeholder="$t('register.right.smscode')">
											<!-- <svg-icon slot="prefix" icon-class="user" class="el-input__icon input-icon" /> -->
										</el-input>
										<el-button v-bind:class="{grey:isGrey,blue:!isGrey}" v-bind:disabled="dis" type="primary"
											@click="getCode">
											<span v-if="show">{{$t('register.right.smscodeButton1')}}</span>
											<span v-else>{{$t('register.right.smscodeButton2')}}({{count}}s)</span>
										</el-button>
									</div>
								</el-form-item>
								<!-- <el-form-item>
									<no-captcha style="width: 100%;" @slideCallback="finishSlide" :reload="reload" />
								</el-form-item> -->
							</el-form>
							<div style="display: flex;justify-content: left;">
								<el-checkbox v-model="ruleForm.rememberMe" style="margin:0px 0px 25px 0px;">{{$t('login.right.remember')}}</el-checkbox>
							</div>
							
							<el-button :loading="loading" size="medium" type="danger" style="width:100%;"
								@click.native.prevent="handleLogin">
								<span v-if="!loading">{{$t('login.right.button1')}}</span>
								<span v-else>{{$t('login.right.button2')}}...</span>
							</el-button>
							
							<div class="register">
								{{$t('login.right.text1')}}
								<!-- <router-link style="color: #d81e06;" class="link-type" :to="'/register'">立即注册</router-link> -->
								<el-link type="danger" href="/register">{{$t('login.right.text2')}}</el-link>
							</div>
							
							<!-- <div class="register2">
								<el-link type="danger" href="/userLogin">{{$t('login.right.text5')}}</el-link>
							</div> -->
						</div>
					</div>
					
					<div class="ri_bottom">
						<span class="ri_bt_text1">深圳市格物思信息科技有限公司
							<span class="text2">粤ICP备2023044805号</span>
						</span>
					</div>
				</div>
			</div>
		</div>
		
		<div class="warp2" v-if="windowWidth<1200">
			<div class="lo_wa_div">
				<div class="right">
					<div class="language">
						<div>EN</div>
						<a-icon class="lu_icon" type="caret-down" />
					</div>
					<el-image style="position: absolute;top: 40px;left:40px;width: 120px;height: 34px;" :src="require('@/assets/img/logo2.png')" :fit="'fill'"></el-image>
					<div class="ri_div">
						<div class="ri_div_title">
							<span class="ri_div_ti_text">{{$t('login.right.title')}}</span>
						</div>
						<el-radio-group v-model="radio" >
						    <el-radio :label="0">{{$t('login.right.text3')}}</el-radio>
						    <el-radio :label="1">{{$t('login.right.text4')}}</el-radio>
						</el-radio-group>
						<div class="ri_div_content" v-if="radio==0">
							<el-form ref="ruleForm" :model="ruleForm" :rules="loginRules" label-width="">
								<el-form-item prop="mobile">
									<div class="inputDiv">
										<a-icon type="user" class="id_icon" />
										<el-input :placeholder="$t('login.right.mobile')" v-model="ruleForm.mobile" style="border: 0px;" type="text" />
									</div>
								</el-form-item>
								<el-form-item prop="pword">
									<div class="inputDiv">
										<a-icon type="lock" class="id_icon" />
										<el-input :placeholder="$t('login.right.pword')" v-model="ruleForm.pword" style="border: 0px;" type="password" />
									</div>
								</el-form-item>
								<!-- <el-form-item>
									<no-captcha style="width: 100%;" v-if="noCaptchaType==true" @slideCallback="finishSlide" :reload="reload" />
								</el-form-item> -->
							</el-form>
							<div style="display: flex;justify-content: left;">
								<el-checkbox v-model="ruleForm.rememberMe" style="margin:0px 0px 25px 0px;">{{$t('login.right.remember')}}</el-checkbox>
							</div>
							
							<el-button :loading="loading" size="medium" type="danger" style="width:100%;"
								@click.native.prevent="handleLogin">
								<span v-if="!loading">{{$t('login.right.button1')}}</span>
								<span v-else>{{$t('login.right.button2')}}...</span>
							</el-button>
							
							<div class="register">
								{{$t('login.right.text1')}}
								<!-- <router-link style="color: #d81e06;" class="link-type" :to="'/register'">立即注册</router-link> -->
								<el-link type="danger" href="/register">{{$t('login.right.text2')}}</el-link>
							</div>
							
							<!-- <div class="register2">
								<el-link type="danger" href="/userLogin">{{$t('login.right.text5')}}</el-link>
							</div> -->
						</div>
						
						<div class="ri_div_content" v-if="radio==1">
							<el-form ref="ruleForm" :model="ruleForm2" :rules="loginRules" label-width="">
								<el-form-item prop="mobile">
									<div class="inputDiv">
										<a-icon type="user" class="id_icon" />
										<el-input :placeholder="$t('login.right.mobile')" v-model="ruleForm2.mobile" style="border: 0px;" type="text" />
									</div>
								</el-form-item>
								<el-form-item prop="smscode">
									<div style="display: flex;">
										<el-input style="margin-right: 20px;" v-model="ruleForm2.smscode" type="text" auto-complete="off"
											:placeholder="$t('register.right.smscode')">
											<!-- <svg-icon slot="prefix" icon-class="user" class="el-input__icon input-icon" /> -->
										</el-input>
										<el-button v-bind:class="{grey:isGrey,blue:!isGrey}" v-bind:disabled="dis" type="primary"
											@click="getCode">
											<span v-if="show">{{$t('register.right.smscodeButton1')}}</span>
											<span v-else>{{$t('register.right.smscodeButton2')}}({{count}}s)</span>
										</el-button>
									</div>
								</el-form-item>
							</el-form>
							
							<el-button :loading="loading" size="medium" type="danger" style="width:100%;"
								@click.native.prevent="handleLogin">
								<span v-if="!loading">{{$t('login.right.button1')}}</span>
								<span v-else>{{$t('login.right.button2')}}...</span>
							</el-button>
							
							<div class="register">
								{{$t('login.right.text1')}}
								<!-- <router-link style="color: #d81e06;" class="link-type" :to="'/register'">立即注册</router-link> -->
								<el-link type="danger" href="/register">{{$t('login.right.text2')}}</el-link>
							</div>
							
							<!-- <div class="register2">
								<el-link type="danger" href="/userLogin">{{$t('login.right.text5')}}</el-link>
							</div> -->
						</div>
					</div>
					
					<div class="ri_bottom">
						<span class="ri_bt_text1">深圳市格物思信息科技有限公司<span class="text2">粤ICP备2023044805号</span></span>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import noCaptcha from '@/components/NoCaptcha/index.vue'
	import Cookies from "js-cookie";
	import { isvalidPhone } from '@/utils/validate'
	import {
		getCode,
		clientLand
	} from '@/api/home/index.js'
	export default {
		name: "Login",
		components: {
			noCaptcha
		},
		data() {
			// 自定义验证
			    const validPhone = (rule, value, callback) => {
			      if (!value) {
			        callback(new Error('请输入电话号码'))
			      } else if (!isvalidPhone(value)) { //判断用户输入的值是否符合规则
			        callback(new Error('请输入正确的11位手机号码'))
			      } else {
			        callback()
			      }
			    }
			return {
				dis: false,
				show: true,
				isGrey: true, //按钮样式
				timer: null, //设置计时器,
				count: "",
				radio:0,
				// 浏览器窗口宽度高度
				windowWidth: 0,
				windowHeight: 0,
				
				reload: false,
				noCaptchaType:false,//是否打开noCaptcha
				loading: false,
				language: 'EN', //中英文切换
				ruleForm: {
					mobile: "",
					pword: "",
					type:1
					// rememberMe: false,
				},
				ruleForm2: {
					mobile: "",
					smscode: "",
					type:2
					// rememberMe: false,
				},
				loginRules: {
					mobile: [{
						required: true,
						trigger: 'blur',
						validator: validPhone,
					}],
					pword: [{
							required: true,
							trigger: "blur",
							message: "请输入您的密码"
						}, 
						{
							min: 8,
							max: 12,
							message: "长度在 8 到 12 个字符",
							trigger: "blur",
						}
					],
					smscode:[{
						required: true,
						trigger: "blur",
						message: "请输入验证码"
					}]
				},
			};
		},
		watch: {
			//双向绑定语言切换
			'$i18n.locale'(val) {
				if (this.$i18n.locale == 'cn') {
					this.language = 'EN';
					this.$store.dispatch("setLanguage", {
					    language: 'cn',
					});
				} else {
					this.language = 'CN';
					this.$store.dispatch("setLanguage", {
					    language: 'en',
					});
				}
				this.route=this.$route.path;
			},
			radio(val){
				const mobile = Cookies.get("mobile");
				const pword = Cookies.get("pword");
				this.ruleForm.mobile=mobile;
				this.ruleForm.pword=pword;
				this.ruleForm2.mobile=mobile;
				this.ruleForm2.smscode="";
				
			}
		},
		created() {
			let that = this;
			that.windowWidth = document.body.clientWidth;
			that.windowHeight = document.body.clientHeight;
			if(this.windowWidth<1200){
				this.noCaptchaType=true
			}else{
				this.noCaptchaType=false
			}
			this.$i18n.locale = this.$store.state.selectForm.language;
			// console.log(this.$store)
			if (this.$i18n.locale == 'cn') {
				this.language = 'EN'
			} else {
				this.language = 'CN'
			}
			this.getCookie();
		},
		mounted() {
			let that = this;
			that.windowWidth = document.body.clientWidth;
			that.windowHeight = document.body.clientHeight;
			window.onresize = () => {
				return (() => {
					this.windowWidth = document.body.clientWidth;
					this.windowHeight = document.body.clientHeight;
					if(this.windowWidth<1200){
						this.noCaptchaType=true
					}else{
						this.noCaptchaType=false
					}
					// console.log(this.noCaptchaType)
				})();
			};
		},
		methods: {
			// ------------------------------完成滑动---------------------------------------
			finishSlide(data) {
				// 按需使用返回值
				// console.log('会话ID', data.sessionId)
				// console.log('签名串', data.sig)
				// console.log('滑块请求的token', data.token)
			
				//      if (data.cls === false) {//验证加载失败
				//         this.loginForm.cls = false
				//       }
				//  
				//       if (data.sessionId) {//非人机操作
				//         this.loginForm.scene = data.scene
				//         this.loginForm.ncToken = data.token
				//         this.loginForm.sig = data.sig
				//         this.loginForm.sessionId = data.sessionId
				//         delete this.loginForm.cls
				//       }
			
				//       if (this.loginForm.username && this.loginForm.password) {//填过用户名密码
				//         this.login()
				//       }
			},
			
			//----------------------获取二维码-----------------------------
			getCode: function() {
				let data = {
					mobile: this.ruleForm2.mobile
				}
				getCode(data).then(res => {
					// console.log(res.code)
					if(res.code=="200"){
						let TIME_COUNT = 60;
						if (!this.timer) {
							this.count = TIME_COUNT;
							this.isGrey = false;
							this.show = false;
							this.dis = true;
							this.timer = setInterval(() => {
								if (this.count > 0 && this.count <= TIME_COUNT) {
									this.count--;
								} else {
									this.dis = false;
									this.isGrey = true;
									this.show = true;
									clearInterval(this.timer);
									this.timer = null;
								}
							}, 1000);
						}
					}else{
						this.$message.error(res.data.msg);
					}
				})
			},
			
			//---------------------语言切换--------------------------
			i18nClick:function(){
				this.drawer=false;
				if(this.$i18n.locale == 'cn'){
					this.$i18n.locale = 'en';
					this.$store.dispatch("setLanguage", {
					    language: 'en',
					});
					// console.log(this.$store.state.selectForm.language)
				}else{
					this.$i18n.locale = 'cn';
					this.$store.dispatch("setLanguage", {
					    language: 'cn',
					});
					// console.log(this.$store.state.selectForm.language)
				}
			},
			
			//---------------------获取cookie缓存----------------------------------------
			getCookie() {
				const mobile = Cookies.get("mobile");
				const pword = Cookies.get("pword");
				const rememberMe = Cookies.get('rememberMe')
				this.ruleForm = {
					mobile: mobile === undefined ? this.ruleForm.mobile : mobile,
					pword: pword === undefined ? this.ruleForm.pword : pword,
					type: 1,
					rememberMe: rememberMe === undefined ? false : Boolean(rememberMe)
				};
			},
			
			//-------------------------登录接口---------------------------------
			handleLogin: function() {
				// console.log(this.ruleForm)
				var dateTime = new Date();
				var minutes = 30;
				dateTime.setTime(dateTime.getTime() + (minutes * 60 * 1000));
				this.$refs.ruleForm.validate(valid => {
					console.log(valid)
					if (valid) {
						if (this.ruleForm.rememberMe) {
							Cookies.set("mobile", this.ruleForm.mobile, {
								expires: 60 * 60 * 24 * 30
							});
							Cookies.set("pword", this.ruleForm.pword, {
								expires: 60 * 60 * 24 * 30
							});
							Cookies.set('rememberMe', this.ruleForm.rememberMe, {
								expires: 60 * 60 * 24 * 30
							});
						} else {
							Cookies.remove("mobile");
							Cookies.remove("pword");
							Cookies.remove('rememberMe');
						}
						if(this.radio==0){
							// console.log(this.ruleForm)
							clientLand(this.ruleForm).then(res => {
								// console.log(res.code)
								if(res.code=="200"){
									Cookies.set("userInfo2", JSON.stringify(res.data), {
										expires: 60 * 60 * 24 * 30
									});
									Cookies.set("role2", res.role, {
										expires: 60 * 60 * 24 * 30
									});
									this.$router.replace({
										path: '/order/home',
									})
								}else{
									this.$message.error(res.data.msg);
								}
							})
						}else{
							clientLand(this.ruleForm2).then(res => {
								// console.log(res.code)
								if(res.code=="200"){
									Cookies.set("userInfo2", JSON.stringify(res.data), {
										expires: 30
									});
									Cookies.set("role2", res.role, {
										expires: 30
									});
									this.$router.push({
										path: '/order/home',
									})
								}else{
									this.$message.error(res.data.msg);
								}
							})
						}
					}
				});
			}
		}
	};
</script>

<style lang="less" scoped>
	
	.language{
		display: flex;
		position: absolute;
		top: 60px;
		right: 60px;
		align-items: center;
		font-size: 15px;
		cursor: pointer;
		
		.lu_icon{
			padding-left: 5px;
		}
	}
	
	.language:hover{
		color: #d81e06;
	}
	
	@media only screen and (min-width:1920px){
		.login {
			display: flex;
			justify-content: center;
			align-items: center;
			height: 100%;
			background-image: url("../../assets/img/banner5.jpg");
			background-size: cover;
			
			.warp {
				display: flex;
				justify-content: center;
				align-items: center;
				height: 100%;
				width: 100%;
				background-size: cover;
				background: rgba(0, 0, 0, .6);
				position: relative;
				
				.lo_wa_div{
					position: absolute;
					width: 100%;
					height: 100%;
					display: flex;
					
					.left{
						width: 70%;
						padding: 40px;
						display: flex;
						align-items: center;
						position: relative;
						
						.le_logo{
							width: 200px;
							position: absolute;
							top: 80px;
							left: 40px;
						}
						
						.le_text1{
							color: #fff;
							font-size: 20px;
							font-weight: bold;
						}
						
						.le_text2{
							color: #fff;
						}
						
						.le_button{
							margin-top: 20px;
							margin-right: 20px;
						}
					}
					
					.right{
						width: 30%;
						background: #fff;
						display: flex;
						align-items: center;
						position: relative;
						
						.ri_div{
							width: 100%;
							text-align: center;
							margin-bottom: 150px;
							
							.ri_div_title{
								font-size: 6px;
								color: #000;
								font-weight: 500;
								padding-top: 10px;
								text-align: center;
								font-size: 20px;
								font-weight: bold;
								margin-bottom: 30px;
								
								.ri_div_ti_text{
									border-bottom: 2px solid #d81e06;
									padding-bottom: 10px;
								}
							}
							
							.ri_div_content{
								padding: 10px 60px;
								
								.inputDiv{
									display: flex;
									align-items: center;
									border-bottom: 1px solid #dddddd;
									
									.id_icon{
										color: #d81e06;
										font-size: 20px;
										border-right: 2px solid #d81e06;
										padding-right: 10px;
									}
								}
								
								.register{
									padding: 20px;
									display: flex;
									align-items: center;
									justify-content: center;
								}
								
								.register2{
									padding: 0 20px 20px 20px;
									display: flex;
									align-items: center;
									justify-content: center;
								}
							}
						}
						
						.ri_bottom{
							position: fixed;
							width: 100%;
							text-align: center;
							bottom: 0;
							z-index: 9999;
							right: 0;
							width: 30%;
							padding-bottom: 30px;
							
							.ri_bt_text1{
								font-size: 5px;
								
								.text2{
									font-size: 5px;
									padding-left: 10px;
								}
							}
						}
					}
				}
			}
		}
	}
	
	@media only screen and (max-width:1920px){
		.login {
			display: flex;
			justify-content: center;
			align-items: center;
			height: 100%;
			background-image: url("../../assets/img/banner5.jpg");
			background-size: cover;
			
			.warp {
				display: flex;
				justify-content: center;
				align-items: center;
				height: 100%;
				width: 100%;
				background-size: cover;
				background: rgba(0, 0, 0, .6);
				position: relative;
				
				.lo_wa_div{
					position: absolute;
					width: 100%;
					height: 100%;
					display: flex;
					
					.left{
						width: 70%;
						padding: 40px;
						display: flex;
						align-items: center;
						position: relative;
						
						.le_logo{
							width: 200px;
							position: absolute;
							top: 80px;
							left: 40px;
						}
						
						.le_text1{
							color: #fff;
							font-size: 20px;
							font-weight: bold;
						}
						
						.le_text2{
							color: #fff;
						}
						
						.le_button{
							margin-top: 20px;
							margin-right: 20px;
						}
					}
					
					.right{
						width: 30%;
						background: #fff;
						display: flex;
						align-items: center;
						position: relative;
						
						.ri_div{
							width: 100%;
							text-align: center;
							margin-bottom: 150px;
							
							.ri_div_title{
								font-size: 6px;
								color: #000;
								font-weight: 500;
								padding-top: 10px;
								text-align: center;
								font-size: 20px;
								font-weight: bold;
								margin-bottom: 30px;
								
								.ri_div_ti_text{
									border-bottom: 2px solid #d81e06;
									padding-bottom: 10px;
								}
							}
							
							.ri_div_content{
								padding: 10px 60px;
								
								.inputDiv{
									display: flex;
									align-items: center;
									border-bottom: 1px solid #dddddd;
									
									.id_icon{
										color: #d81e06;
										font-size: 20px;
										border-right: 2px solid #d81e06;
										padding-right: 10px;
									}
								}
								
								.register{
									padding: 20px;
									display: flex;
									align-items: center;
									justify-content: center;
								}
								
								.register2{
									padding: 0 20px 20px 20px;
									display: flex;
									align-items: center;
									justify-content: center;
								}
							}
						}
						
						.ri_bottom{
							position: fixed;
							width: 100%;
							text-align: center;
							bottom: 0;
							z-index: 9999;
							right: 0;
							width: 30%;
							padding-bottom: 30px;
							
							.ri_bt_text1{
								font-size: 5px;
								
								.text2{
									font-size: 5px;
									padding-left: 10px;
								}
							}
						}
					}
				}
			}
		}
	}
	
	@media only screen and (max-width:1919px) and (min-width:1440px){
		.login {
			display: flex;
			justify-content: center;
			align-items: center;
			height: 100%;
			background-image: url("../../assets/img/banner5.jpg");
			background-size: cover;
			
			.warp {
				display: flex;
				justify-content: center;
				align-items: center;
				height: 100%;
				width: 100%;
				background-size: cover;
				background: rgba(0, 0, 0, .6);
				position: relative;
				
				.lo_wa_div{
					position: absolute;
					width: 100%;
					height: 100%;
					display: flex;
					
					.left{
						width: 70%;
						padding: 40px;
						display: flex;
						align-items: center;
						position: relative;
						
						.le_logo{
							width: 200px;
							position: absolute;
							top: 80px;
							left: 40px;
						}
						
						.le_text1{
							color: #fff;
							font-size: 20px;
							font-weight: bold;
						}
						
						.le_text2{
							color: #fff;
						}
						
						.le_button{
							margin-top: 20px;
							margin-right: 20px;
						}
					}
					
					.right{
						width: 30%;
						background: #fff;
						display: flex;
						align-items: center;
						position: relative;
						
						.ri_div{
							width: 100%;
							text-align: center;
							margin-bottom: 150px;
							
							.ri_div_title{
								font-size: 6px;
								color: #000;
								font-weight: 500;
								padding-top: 10px;
								text-align: center;
								font-size: 20px;
								font-weight: bold;
								margin-bottom: 30px;
								
								.ri_div_ti_text{
									border-bottom: 2px solid #d81e06;
									padding-bottom: 10px;
								}
							}
							
							.ri_div_content{
								padding: 10px 60px;
								
								.inputDiv{
									display: flex;
									align-items: center;
									border-bottom: 1px solid #dddddd;
									
									.id_icon{
										color: #d81e06;
										font-size: 20px;
										border-right: 2px solid #d81e06;
										padding-right: 10px;
									}
								}
								
								.register{
									padding: 20px;
									display: flex;
									align-items: center;
									justify-content: center;
								}
								
								.register2{
									padding: 0 20px 20px 20px;
									display: flex;
									align-items: center;
									justify-content: center;
								}
							}
						}
						
						.ri_bottom{
							position: fixed;
							width: 100%;
							text-align: center;
							bottom: 0;
							z-index: 9999;
							right: 0;
							width: 30%;
							padding-bottom: 30px;
							
							.ri_bt_text1{
								font-size: 5px;
								
								.text2{
									font-size: 5px;
									padding-left: 10px;
								}
							}
						}
					}
				}
			}
		}
	}
	
	@media only screen and (max-width:1439px) and (min-width:1200px){
		.login {
			display: flex;
			justify-content: center;
			align-items: center;
			height: 100%;
			background-image: url("../../assets/img/banner5.jpg");
			background-size: cover;
			
			.warp {
				display: flex;
				justify-content: center;
				align-items: center;
				height: 100%;
				width: 100%;
				background-size: cover;
				background: rgba(0, 0, 0, .6);
				position: relative;
				
				.lo_wa_div{
					position: absolute;
					width: 100%;
					height: 100%;
					display: flex;
					
					.left{
						width: 60%;
						padding: 40px;
						display: flex;
						align-items: center;
						position: relative;
						
						.le_logo{
							width: 200px;
							position: absolute;
							top: 80px;
							left: 40px;
						}
						
						.le_text1{
							color: #fff;
							font-size: 20px;
							font-weight: bold;
						}
						
						.le_text2{
							font-size: 15px;
							color: #fff;
						}
						
						.le_button{
							margin-top: 20px;
							margin-right: 20px;
						}
					}
					
					.right{
						width: 40%;
						background: #fff;
						display: flex;
						align-items: center;
						position: relative;
						
						.ri_div{
							width: 100%;
							text-align: center;
							margin-bottom: 150px;
							
							.ri_div_title{
								font-size: 6px;
								color: #000;
								font-weight: 500;
								padding-top: 10px;
								text-align: center;
								font-size: 20px;
								font-weight: bold;
								margin-bottom: 30px;
								
								.ri_div_ti_text{
									border-bottom: 2px solid #d81e06;
									padding-bottom: 10px;
								}
							}
							
							.ri_div_content{
								padding: 10px 60px;
								
								.inputDiv{
									display: flex;
									align-items: center;
									border-bottom: 1px solid #dddddd;
									
									.id_icon{
										color: #d81e06;
										font-size: 20px;
										border-right: 2px solid #d81e06;
										padding-right: 10px;
									}
								}
								
								.register{
									padding: 20px;
									display: flex;
									align-items: center;
									justify-content: center;
								}
								
								.register2{
									padding: 0 20px 20px 20px;
									display: flex;
									align-items: center;
									justify-content: center;
								}
							}
						}
						
						.ri_bottom{
							position: fixed;
							width: 100%;
							text-align: center;
							bottom: 0;
							z-index: 9999;
							right: 0;
							width: 40%;
							padding-bottom: 30px;
							
							.ri_bt_text1{
								font-size: 5px;
								
								.text2{
									font-size: 5px;
									padding-left: 10px;
								}
							}
						}
					}
				}
			}
		}
	}
	
	@media only screen and (max-width:1199px) and (min-width:768px){
		
			.login {
				display: flex;
				justify-content: center;
				align-items: center;
				height: 100%;
				background-image: url("../../assets/img/banner5.jpg");
				background-size: cover;
				
				.warp2 {
					display: flex;
					justify-content: center;
					align-items: center;
					height: 100%;
					width: 100%;
					background-size: cover;
					background: rgba(0, 0, 0, .6);
					position: relative;
					
					.lo_wa_div{
						position: absolute;
						width: 100%;
						height: 100%;
						display: flex;
						
						
						.right{
							width: 100%;
							background: #fff;
							display: flex;
							align-items: center;
							position: relative;
							
							.ri_div{
								width: 100%;
								text-align: center;
								margin-bottom: 150px;
								
								.ri_div_title{
									font-size: 6px;
									color: #000;
									font-weight: 500;
									padding-top: 10px;
									text-align: center;
									font-size: 20px;
									font-weight: bold;
									margin-bottom: 30px;
									
									.ri_div_ti_text{
										border-bottom: 2px solid #d81e06;
										padding-bottom: 10px;
									}
								}
								
								.ri_div_content{
									padding: 10px 60px;
									
									.inputDiv{
										display: flex;
										align-items: center;
										border-bottom: 1px solid #dddddd;
										
										.id_icon{
											color: #d81e06;
											font-size: 20px;
											border-right: 2px solid #d81e06;
											padding-right: 10px;
										}
									}
									
									.register{
										padding: 20px;
										display: flex;
										align-items: center;
										justify-content: center;
									}
									
									.register2{
										padding: 0 20px 20px 20px;
										display: flex;
										align-items: center;
										justify-content: center;
									}
								}
							}
							
							.ri_bottom{
								position: fixed;
								width: 100%;
								text-align: center;
								bottom: 0;
								z-index: 9999;
								padding-bottom: 30px;
								
								.ri_bt_text1{
									font-size: 5px;
									
									.text2{
										font-size: 5px;
										padding-left: 10px;
									}
								}
							}
						}
					}
				}
			}
		
	}
	
	@media only screen and (max-width:767px){
		
			
				.login {
					display: flex;
					justify-content: center;
					align-items: center;
					height: 100%;
					background-image: url("../../assets/img/banner5.jpg");
					background-size: cover;
					
					.warp2 {
						display: flex;
						justify-content: center;
						align-items: center;
						height: 100%;
						width: 100%;
						background-size: cover;
						background: rgba(0, 0, 0, .6);
						position: relative;
						
						.lo_wa_div{
							position: absolute;
							width: 100%;
							height: 100%;
							display: flex;
							
							
							.right{
								width: 100%;
								background: #fff;
								display: flex;
								align-items: center;
								position: relative;
								
								.ri_div{
									width: 100%;
									text-align: center;
									margin-bottom: 150px;
									
									.ri_div_title{
										font-size: 6px;
										color: #000;
										font-weight: 500;
										padding-top: 10px;
										text-align: center;
										font-size: 20px;
										font-weight: bold;
										margin-bottom: 30px;
										
										.ri_div_ti_text{
											border-bottom: 2px solid #d81e06;
											padding-bottom: 10px;
										}
									}
									
									.ri_div_content{
										padding: 10px 60px;
										
										.inputDiv{
											display: flex;
											align-items: center;
											border-bottom: 1px solid #dddddd;
											
											.id_icon{
												color: #d81e06;
												font-size: 20px;
												border-right: 2px solid #d81e06;
												padding-right: 10px;
											}
										}
										
										.register{
											padding: 20px;
											display: flex;
											align-items: center;
											justify-content: center;
										}
										
										.register2{
											padding: 0 20px 20px 20px;
											display: flex;
											align-items: center;
											justify-content: center;
										}
									}
								}
								
								.ri_bottom{
									position: fixed;
									width: 100%;
									text-align: center;
									bottom: 0;
									z-index: 9999;
									padding-bottom: 30px;
									
									.ri_bt_text1{
										font-size: 5px;
										
										.text2{
											font-size: 5px;
											padding-left: 10px;
										}
									}
								}
							}
						}
					}
				}
			
		
	}
	
	input[type=text]:focus {
		outline: none;
		
	}
	
	input[type=password]:focus {
		outline: none;
		
	}
	
	/deep/.el-input__inner {
		border: 0px;
		box-shadow: 0 0 0 0px;
	}
	
</style>
